export const backgroundVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 0.8, transition: { duration: 1 } },
  exit: { opacity: 0, transition: { duration: 0.4 } },
};

export const blockVariants = {
  initial: { x: "calc(100% + 1rem)" },
  animate: {
    x: 0,
    transition: { duration: 0.8, ease: [0.75, 0, 0.65, 0.9] },
  },
  exit: { x: "calc(100% + 1rem)", transition: { duration: 0.4 } },
};

export const textVariants = {
  initial: { y: "100%" },
  animate: (custom) => ({
    y: 0,
    transition: {
      duration: custom.duration,
      delay: custom.delay,
      ease: [0, 0.55, 0.45, 1],
    },
  }),
  exit: { y: "100%" },
};

export const inputVariants = {
  initial: { y: 15, opacity: 0 },
  animate: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: custom.duration,
      delay: custom.delay,
      ease: [0, 0.55, 0.45, 1],
    },
  }),
  exit: { y: 15, opacity: 0 },
};
